<template>

	<div>
<!--		<van-nav-bar class="navbar" title="工资" fixed left-arrow @click-left="leftReturn" />-->
		<rxNavBar title="工资" androidOrIOSFlag="true"></rxNavBar>

		<!-- 顶部固定区域-->
		<div class="top"> *工资属于敏感信息，注意保密 </div>

    <div v-if="!passwordCheck" class="loginSalary">
      <van-form @submit="checkPassword">
        <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="color: steelblue;font-size: 14px;padding: 10px 20px;text-align: right" @click="forgetPassword">设置密码</div>
        <div style="box-sizing: border-box;padding-left: 10px;color: #7d7b7b;font-size: 14px">
          <div>注：</div>
          <div>1.首次查看工资条前，请先设置密码</div>
          <div>2.此密码与登录密码无关，仅做查看工资条使用</div>
          <div>3.密码为6~12位的数字、字母组合</div>
        </div>


        <div style="margin: 16px;">
          <van-button round block color="#ff5d3b" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>

		<!-- 主体的列表们 -->
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-if="passwordCheck">
			<div class="lists">
				<div class="detail" v-for=" (item,i) in paylist " :key="i">
					<div :class="item.salaryStatus=='1'?'top-title-orange':'top-title-green'">{{item.salaryStatus == '1' ? '待确认' : '已确认'}}</div>
					<!-- 上半部分 -->
					<div class="tophalf" >
						<div class="month">{{item.salaryTime}}</div>
<!--						<div class="bottomNavigationB" @click="confrim(item.id)" v-if="item.salaryStatus == '1'">确认</div>-->
					</div>
					<!-- 下半部分 -->
					<div class="bottomhalf">
						<div class="allmoney">总金额：</div>
						<div class="money">{{item.actualAmount}}元</div>
						<div class="mingxi" @click="wageDetail(item.id)">明细></div>
					</div>
				</div>
			</div>
		</van-list>


	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
    Form,
    Field,
    Button
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(Popup);
	Vue.use(PullRefresh);
  Vue.use(Form);
  Vue.use(Field);
  Vue.use(Button);

  import {
    queryBaseData,
    wageList,
    confirmWage, checkSalaryPassword
  } from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil,
		getStaffId
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",

		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				currentPage: '1', //当前页
				pageCount: '', //总页数
				numberPage: '10', //每页条数
				count: 0,
				certificateType: '',
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
				paylist: [],

        passwordCheck:false,
        password:'',
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {

      if(JSON.parse(localStorage.getItem('passwordCheck'))){
        this.passwordCheck = true
      }
		},
		//初始化
		mounted() {

		},
    beforeRouteLeave(to, from, next) {
      if(to.name!='wageDetail'){  //不去详情
        localStorage.removeItem('passwordCheck');

      }else{
      }
      next()
    },
    // beforeRouteEnter(to, from, next) {
    //   // console.log(from.name)
    //   if(from.name==='wageDetail'){  //详情返回
    //
    //   }
    //   next()
    // },
		methods: {
      checkPassword(){
        let that = this
        let initData = {}
        initData.globaluserId = globaluserId()
        initData.managePassword = this.password
        checkSalaryPassword(initData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            that.passwordCheck = true
            localStorage.setItem('passwordCheck', that.passwordCheck)

          })
        })
      },
      forgetPassword(){
        this.$router.push({
          name : 'forgotPassword',
          query: {
            enterType:'forgotPassword'
          }
        })
      },
			//工资列表初始化
			wageList() {
				const that = this
				if (that.dictionaryValue == 0) {
					that.dictionaryValue = ""
				}
				let initData = {}
				// debugger
				initData.staff_id = getStaffId() //userid
				initData.currentPage = that.currentPage //当前页
				initData.numberPage = that.numberPage //每页条数
				wageList(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.code == 0) {
							that.pageCount = response.data.data.totalPage //总页数
							var totalPage = response.data.data.pageCount  //总条数
							var list = response.data.data.data
							// console.log(list)
							if (totalPage == 0 || that.pageCount == 0 || !list) { //没有数据时
								that.loading = false
								that.finished = true
							}
							if (list && that.pageCount != 0) { //有数据时
								var currentPage = parseInt(that.currentPage); //当前页
								var pages = that.pageCount; //总页数
								if (pages >= currentPage) { //当前页不大于总页数
									that.currentPage = currentPage + 1
									for (let i = 0; i < list.length; i++) {
										that.paylist.push(list[i])
									}
									that.loading = false
									// console.log("长度=" + that.userLeaseChangeListZ.length)
								} else {
									that.loading = false
									that.finished = true
								}
							}
						} else if (response.data.code != 0) {
							responseUtil.alertMsg(that, response.data.msg)
						} else {
							responseUtil.alertMsg(that, response.data.msg)
						}
						// if (1=1) {
						// 	that.swi = false
						// } else {
						// 	that.swi = true
						// }


					})
				})
			},
			wageDetail(id) {
					this.$router.push({
						name: 'wageDetail',
						query: {
							salaryHistory_id:id
						}
					})
			},
			confrim(e) {
				let that = this
				let initData = {}
				initData.staff_id = getStaffId() //userid
				initData.salaryHistoryDetail_id = e
				confirmWage(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
                        that.wageList()
					})
				})
			},
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			default () { //默认值
			},
			//分页
			onLoad() {
				this.wageList();
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.top {
		color: #FF5D3B;
		position: fixed;
		width: 100%;
		height: 45px;
		line-height: 45px;
		top: 50px;
		font-size: 16px;
		text-align: center;
		background-color: rgb(248, 248, 248);
	}

	.lists {
		margin-top: 50px;
	}

	.detail {
		width: 90%;
		height: 110px;
		border-radius: 8px;
		background-color: white;
		margin: 0 auto;
		margin-bottom: 15px;
		position: relative;
	}

	.tophalf {
		height: 60px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.month {
		float: left;
		font-size: 18px !important;
		font-weight: 700;
		font-size: 22px;
		padding-top: 22px;
		padding-left: 15px;
	}

	.bottomNavigationB {
		height: 30px;
		width: 90px;
		font-size: 12px;
		align-items: center;
		border-radius: 7px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
		line-height: 30px;
		box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
		font-family: PingFangSC-Semibold;
		text-align: center;
		float: right;
		margin-top: 15px;
		margin-right: 10px;
	}

	.allmoney {
		float: left;
		font-size: 16px;
		margin-top: 10px;
		margin-left: 15px;
		font-weight: 600;
	}

	.money {
		float: left;
		margin-top: 12px;
		color: #ACACAC;
		font-size: 13px;
	}

	.mingxi {
		float: left;
		margin-top: 12px;
		color: rgba(255, 93, 59, 1);
		font-size: 13px;
		margin-left: 10px;
		text-decoration: underline
	}
	.top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 0 8px;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
		position: absolute;
		right: 0;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius:0 8px;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
		position: absolute;
		right: 0;

    }
	.top-title-orange{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius:0 8px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
		position: absolute;
		right: 0;
    }


  .loginSalary{
    width: 90%;
    min-height: 100px;
    background-color: #fff;
    margin:50% auto;
    box-sizing: border-box;
    padding: 15px 0px 10px;
    border-radius: 10px;
  }
</style>
